//
// Avatar
//

// Base
.image-public-input {
  width: 100%;
  position: relative;
  display: inline-block;
  @include border-radius($border-radius);
  background-repeat: no-repeat;
  background-size: cover;

  .image-public-header {
    width: 100%;
    height: 200px;
    @include border-radius($border-radius);
    background-repeat: no-repeat;
    background-size: cover;
  }

  // Change Button
  [data-action="change"] {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;

    input {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
      opacity: 0;
    }
  }

  // Cancel & Remove Buttons
  [data-action="cancel"],
  [data-action="remove"] {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }

  [data-action="cancel"] {
    display: none;
  }

  // Input Changed State
  &.image-public-header {
    [data-action="cancel"] {
      display: flex;
    }

    [data-action="remove"] {
      display: none;
    }
  }

  // Input Empty State
  &.image-input-empty {
    [data-action="remove"],
    [data-action="cancel"] {
      display: none;
    }
  }

  // Circle style
  &.image-input-circle {
    border-radius: 50%;

    // Wrapper
    .image-public-header {
      border-radius: 50%;
    }

    // Change Control
    [data-action="change"] {
      right: 5px;
      top: 5px;
    }

    // Cancel & Remove Buttons
    [data-action="cancel"],
    [data-action="remove"] {
      right: 5px;
      bottom: 5px;
    }
  }

  &.image-header-input-outline {
    .image-public-header {
      width: 100%;
      border: 3px solid $white;
      box-shadow: $box-shadow;
    }
  }
}
.image-input {
  position: relative;
  display: inline-block;
  @include border-radius($border-radius);
  background-repeat: no-repeat;
  background-size: cover;

  // Wrapper
  .image-input-wrapper {
    width: 120px;
    height: 120px;
    @include border-radius($border-radius);
    background-repeat: no-repeat;
    background-size: cover;
  }

  // Change Button
  [data-action="change"] {
    cursor: pointer;
    position: absolute;
    right: -10px;
    top: -10px;

    input {
      width: 0 !important;
      height: 0 !important;
      overflow: hidden;
      opacity: 0;
    }
  }

  // Cancel & Remove Buttons
  [data-action="cancel"],
  [data-action="remove"] {
    position: absolute;
    right: -10px;
    bottom: -5px;
  }

  [data-action="cancel"] {
    display: none;
  }

  // Input Changed State
  &.image-input-changed {
    [data-action="cancel"] {
      display: flex;
    }

    [data-action="remove"] {
      display: none;
    }
  }

  // Input Empty State
  &.image-input-empty {
    [data-action="remove"],
    [data-action="cancel"] {
      display: none;
    }
  }

  // Circle style
  &.image-input-circle {
    border-radius: 50%;

    // Wrapper
    .image-input-wrapper {
      border-radius: 50%;
    }

    // Change Control
    [data-action="change"] {
      right: 5px;
      top: 5px;
    }

    // Cancel & Remove Buttons
    [data-action="cancel"],
    [data-action="remove"] {
      right: 5px;
      bottom: 5px;
    }
  }

  // Bordered style
  &.image-input-outline {
    .image-input-wrapper {
      border: 3px solid $white;
      box-shadow: $box-shadow;
    }
  }
}

.social-field {
  width: 100%;
  height: 38px;
  border: 1px solid hsl(0deg, 0%, 80%);
  padding: 2px 8px;
  border-radius: 4px;
}

.img-wrapper-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgba(0, 0, 0, 0.08);
  min-height: 300px;
  img {
    height: 300px;
    width: fit-content;
  }
}

.graph-wrapper {
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  //   width: 100%;
  .imgWrapper {
    position: relative;
    min-width: 115px;
    height: 90px;
  }

  .image {
    opacity: 1;
    position: absolute;
    display: block;
    transition: 0.5s ease;
    // backface-visibility: hidden;
    height: 100%;
    min-width: 120px;

    &:hover {
      opacity: 0.5;
    }
  }
  .middle {
    position: absolute;
    top: 10px;
    right: 0;
    transform: translate(-50%, -50%);

    &:hover {
      visibility: visible;
      opacity: 1;
    }

    .icon {
      //   border: 1px solid black;
      display: grid;
      grid-gap: 10px;
      grid-auto-flow: column;
      font-size: 20px;
    }
  }
}

// .img-wrapper {
//   overflow: hidden;
//   img.hover-zoom:hover {
//     transform: scale(1.25);
//   }
// }
