// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";

// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";

// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}
// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }

.example-highlight > pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;
  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json > pre {
  background: none #fff !important;
}

.example-code > .json {
  background: none #fff !important;
}

.symbol.symbol-45 > svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}
.container {
  max-width: 100%;
  width: 100%;
  min-width: 100%;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.btn-google {
  width: 100%;
  margin: 0 0 25px;
}

.facebook-btn-container {
  margin: 5px 0;
  > span {
    width: 100%;
    margin: 5px 0;
    button {
      width: 100%;
    }
  }
  img {
    width: 23px;
    height: 23px !important;
    margin-left: 10px;
  }
}
//code by mahbubul
.btn-microsoft {
  padding: 10px;
}
a.nav-link {
  margin: 11px 7px;
  font-size: 15px;
}
.bg-dark.navbar-auth .navbar-nav .btn-custom-signin {
  padding: 12px 32px;
}
.btn.btn-info:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-info:focus:not(.btn-text),
.btn.btn-info.focus:not(.btn-text) {
  background: #bd7f0d;
  border-color: #bd7f0d;
  color: #fff;
}
.login.login-1 .login-form {
  margin: auto;
  width: 100%;
  max-width: 70%;
}
@media (min-width: 1220px) {
  .social-login {
    //padding: 31px 120px;
  }
  .forgot-email-login {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #3f3f46 !important;
  }
  .email-login {
    //padding: 16px 84px;
  }
}
button.kep-login-facebook.small {
  border-radius: 0.45rem !important;
}
button.btn-google {
  border-radius: 0.45rem !important;
}

.footer {
  /* padding: 100px 0; */
  //padding: 10px 0;
  background: #fff;
  //margin-top: 40px;
  border-top: 1px solid #ccc;
  width: 100%;
  padding: 20px;
  margin: 0 auto;
}
.login-title h1 {
  font-size: 3rem;
  margin-bottom: 25px;
}
.navbar-brand img {
  width: 150px;
}
.input-special {
  background: white !important;
  border: 2px solid #7a6969 !important;
  height: 40px !important;
  padding-left: 38px !important;
}
.ktbg {
  background: #d6d6d64d !important;
}
.ktbg-login {
  background: white;
  border-radius: 0.9rem;
  padding-top: 20px;
  padding-bottom: 20px;
}
button#kt_login_signin_submit {
  height: 40px;
}
button#kt_signup_submit {
  background: #84b5e8;
  height: 40px;
  color: #ffffff;
  border-color: #84b5e8;
}
.email-icon {
  position: absolute;
  top: 6px;
  left: 20px;
}
.change-password {
  background-image: none !important;
}
.show-hide-password {
  position: absolute;
  top: 6px;
  right: 20px;
  text-align: right;
  cursor: pointer;
  background: none;
  border: none;
  svg {
    // width: 2rem !important;
    // height: 2rem !important;
    z-index: 1000;
    cursor: pointer;
  }
  &.user {
    top: 30px;
    right: 15px;
  }
  &.recovery {
    top: 10px;
  }
}
.pass-icon {
  position: absolute;
  top: 10px;
  left: 20px;
}
.forget-form-icon {
  position: absolute;
  top: 6px;
  left: 20px;
}
@media screen and (max-width: 568px) {
  .seperator {
    display: none;
  }
}
.btn-google {
  margin: 0;
}
.kep-login-facebook {
  text-transform: none !important;
  font-size: 13px !important;
}
button.btn.btn-info.btn-block.btn-microsoft {
  background: #131628;
  margin-top: 10px;
  padding: 10px;
}
button.btn.btn-info.btn-block.btn-microsoft:hover {
  background: #131628;
}
button.btn.btn-info.btn-block.btn-microsoft svg {
  font-size: 15px;
  float: left;
  margin-left: 0;
}
.btn-google {
  background-color: #f44336 !important;
  border-radius: 10px;
}
.btn-google div {
  display: none;
  margin-right: 33px;
  background: none;
  color: white;
  padding: 10px;
  border-radius: 2px;
  font-size: 12px;
}
.btn-google span {
  padding: 10px !important;
  font-weight: 500;
  margin: 0 auto;
}
button.btn.btn-info.btn-block.btn-microsoft:hover {
  background: #353a5a !important;
}
button.kep-login-facebook.small:hover {
  background: #8198d9;
}
.fb-icon {
  position: relative;
  top: 30px;
  left: 8px;
}
img.img-responsive.float-left.google-icon {
  position: relative;
  top: 33px;
  left: 6px;
  z-index: 999;
}
.facebook-btn-container {
  margin: 10px 0;
}
a#kt_login_forgot {
  color: blue !important;
}

#leaflet-container {
  width: 100%;
  //height: 100vh;
  height: calc(100vh - 125px);
  z-index: 1;
  margin-top: 10px;
}

#map {
  position: absolute;
  top: 20px;
  width: 100px;
  height: 40px;
  background-color: white;
  right: 20px;
  z-index: 100;
  border-radius: 12px;
}

#reset {
  width: 100%;
  height: 100%;
  background-color: white;
  font-size: 14px;
  font-weight: bold;
  border-radius: 12px;
  border-color: lightgrey;
  outline: none;
}

.for-pdf {
  width: 925px;
  margin: 15px;
}

.hidden {
  display: none;
}

.vhidden {
  visibility: hidden;
}

.report-pdf {
  width: 954px;

  h3 {
    white-space: nowrap;
  }

  .apexcharts-legend-series {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  .card {
    padding: 15px;
  }

  .page {
    height: 1347px;
  }

  .page1 {
    background-size: cover;
    overflow: hidden;
  }

  .page-other {
    background-repeat: repeat-y;
    background-size: contain;
    overflow: hidden;

    .header {
      height: 298px;
      margin: 0;
      background-size: cover;
    }

    .content-area {
      height: calc(100% - 311px);
      background-color: #ffffff;
    }
  }

  .pdf-footer {
    height: 13px;
    background-size: cover;
  }

  .page1-content {
    height: 311px;
    margin-top: 1023px;
    padding-top: 90px;
    padding-left: 100px;

    h2 {
      color: #46763b;
      font-size: 75px;
      font-weight: bold;
    }
    h3 {
      font-size: 28px;
    }
  }
}

.address-list {
  list-style: none;
  padding: 0;
}

.address-list li {
  padding: 5px;
}

.styles_autocomplete-container__1OBtp input {
  background-clip: padding-box;
  background-color: rgb(243, 246, 249);
  border-color: rgb(243, 246, 249);
  border-radius: 5.46px;
  border-style: solid;
  border-width: 1px;
  line-height: 21.06px;
  padding: 10.725px 37.96px 10.725px 18.46px;
}

.input-chart {
  width: 100%;
  border: 2px solid #aaa;
  border-radius: 4px;
  margin: 8px 0;
  outline: none;
  padding: 8px;
  box-sizing: border-box;
  transition: 0.3s;
}

.app-table {
  text-align: center;
  font-family: "Roboto", sans-serif;
}
.top-section-dropdown {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  padding: 10px 20px;
  background-color: rgb(243, 246, 249);
  margin: 20px 20px 40px;
  border-radius: 6px;
}

.app-chart {
  text-align: center;
  position: absolute;
  /* display: flex; */
  top: 65px;
  left: 265px;
  right: 0;
  bottom: 0;
  background-color: rgb(238, 240, 248);
  font-family: "Roboto", sans-serif;
}

.top-section-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: rgb(243, 246, 249);
  margin: 20px;
  border-radius: 6px;
  // font-family: "Roboto", sans-serif;
}
.top-section-datepicker {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(243, 246, 249);
  border-radius: 6px;
  // font-family: "Roboto", sans-serif;
}
.date-picker-input {
  height: 35px;
  border-radius: 4px;
  border-color: rgb(204, 204, 204);
  border-width: 1.8px;
  padding-left: 10px;
  outline-color: rgb(46, 135, 252);
}
.datepicker {
  height: 35px;
  border-radius: 4px;
  border-color: rgb(204, 204, 204);
  border-width: 1.8px;
  padding-left: 10px;
  outline-color: rgb(46, 135, 252);
}

.table-heading {
  align-self: flex-start;
  background-color: #33415c;
  padding: 10px;
  color: white;
  text-align: left;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 18px;
  font-weight: bold;
}
.table-data {
  white-space: nowrap;
}
.empty-space {
  flex: auto;
}

.button-group {
  border-color: rgb(204, 204, 204);
  margin-right: 20px;
  margin-top: 10px;
  justify-content: center;
  align-items: center;
}

.date-picker {
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.date-picker {
  justify-content: center;
  align-items: center;
}
.energy-table-div {
  background-color: rgb(243, 246, 249);
  margin-top: 15px;
  margin-left: -14px;
  margin-right: -14px !important;
  border-radius: 12px;
  width: 102%;
}

.table-div {
  background-color: rgb(243, 246, 249);
  margin: 6px -2px;
  border-radius: 6px;
  width: 100%;
}

.table-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-right: 20px;
  margin-bottom: 20px;
}

.table-actions-alignment {
  text-align: end;
}

.table-title {
  //align-self: flex-start;
  background-color: #33415c;
  padding: 10px;
  color: white;
  text-align: left;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  h3 {
    display: flex;
    align-items: flex-start;
    color: white;
  }
  .table-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-items: center;
  }
}

.table-title-new {
  //align-self: flex-start;
  background-color: #33415c;
  padding: 20px 10px;
  color: white;
  text-align: left;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  h3 {
    display: flex;
    align-items: flex-start;
    color: white;
  }
  .table-toolbar {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-items: center;
  }
}

.table-heading-left {
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}

.table-heading-center {
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.table-heading-right {
  text-align: right;
  font-size: 14px;
  font-weight: bold;
}

.table-row-left {
  text-align: left;
  font-size: 12px;
}
.table-level1 {
  background-color: #dcdcdc;
  font-weight: bold;
  text-align: center;
  font-size: 12px;
  white-space: nowrap;
}
.table-data-center {
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
}
.table-data-left-level1 {
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  white-space: nowrap;
  background-color: #dcdcdc !important;
}
.table-data-left-level2 {
  // text-align: left;
  font-size: 12px;
  text-indent: 20px;
  font-weight: 500;
  white-space: nowrap;
}
.table-data-left-level3 {
  text-align: left;
  font-size: 12px;
  text-indent: 40px;
  font-weight: 500;
  white-space: nowrap;
}

.table-data-left-level4 {
  text-align: left;
  font-size: 12px;
  text-indent: 60px;
  font-weight: 500;
  white-space: nowrap;
}

.table-row-center {
  text-align: center;
  font-size: 12px;
}

.table-row-right {
  text-align: right;
  font-size: 12px;
}
.table-row-text {
  padding-top: 6px;
}
.chart-layout {
  margin-left: -14px;
  margin-top: 15px;
  background-color: rgba(243, 246, 251, 0);
  margin-right: 10px;
  padding: 0px;
  border-radius: 8px;
  width: 102%;
}

.broad-casting {
  z-index: 9;
  top: 0px;
}

.account-table-btn {
  background-color: #46763c;
  color: white;
  // width: 60px;
  margin-left: auto;
  position: relative;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 5px;
  border: none;
  border-radius: 4px;
  margin-bottom: -58px;
}

.account-table-btn1 {
  flex: 1;
  background-color: #46763c;
  color: white;
  text-align: center;
  padding: 5px 0;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 20px;
}
.active-check-container {
  // flex: 1;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-right: 20px;
  // margin-left: 25px;
  padding-left: 15px;
  width: 25px;
  // background-color: #20c0ed;
}
.active-check {
  height: 25px;
  width: 25px;
  // padding-right: 20px;
  // align-self: center;
}
.account-table-edit {
  background-color: #20c0ed;
  flex: 1;
  color: white;
  text-align: center;
  padding: 5px 0;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 20px;
}
.account-table-view {
  background-color: #3f8db9;
  flex: 1;
  color: white;
  text-align: center;
  padding: 5px 0;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 20px;
}
.account-table-btn2 {
  background-color: rgb(89, 187, 162);
  color: white;
  width: 60px;
  margin-left: auto;
  position: relative;
  align-self: flex-end;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 6px 0;
  border: none;
  border-radius: 4px;
  margin-right: 20px;
  margin-top: 20px;
}

.account-table-btn1-disabled {
  flex: 1;
  background-color: gray;
  color: white;
  text-align: center;
  padding: 5px 0;
  border: none;
  border-radius: 4px;
  margin-left: auto;
  margin-right: 20px;
}

// .table-shift-up {
//   margin-top: -30px;
// }

.button-group {
  display: flex;
  margin-left: auto;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba(0, 0, 0, 0.6);
}

.modal-main {
  position: fixed;
  background: white;
  width: 50%;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px;
  border-radius: 4px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.custom-modal-close-btn {
  border: none;
  background-color: rgb(89, 187, 162);
  color: white;
  margin-left: auto;
  margin-right: 15px;
  font-weight: bold;
  border-radius: 4px;
}

.modal-picker {
  padding-left: 80px;
  display: none;
}

.modal-name-tf-container {
  margin-bottom: 30px;
  margin-top: 30px;
}
.csv-container {
  margin-top: 20px;
}

.modal-name-tf {
  width: 60%;
  height: 35px;
  margin-left: 20px;
  padding-left: 8px;
}
.modal-title {
  font-size: large;
  font-weight: bold;
  padding-bottom: 20px;
}
.modal-upload {
  background-color: rgb(89, 187, 162);
  color: white;
  text-align: center;
  padding: 6px 0;
  border: none;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;
}
.picker-text-center {
  text-align: center;
  margin-right: 20px;
}
.ml10 {
  margin-left: 10px;
}
.ml14 {
  margin-left: 14px;
}
.ml20 {
  margin-left: 20px;
}
.mr10 {
  margin-right: 10px;
}
.mr14 {
  margin-right: 14px;
}
.mr20 {
  margin-right: 20px;
}
.mr53 {
  margin-right: 53px;
}
.mr150 {
  margin-right: 150px;
}
.mt12 {
  margin-top: 12px;
}
.mt8 {
  margin-top: 8px;
}
.mt20 {
  margin-top: 20px;
}
.mt32 {
  margin-top: 32px;
}
.mb10 {
  margin-bottom: 20px;
}
.mb8 {
  margin-bottom: 8px;
}
.mb12 {
  margin-bottom: 12px;
}
.mb20 {
  margin-bottom: 20px;
}
.mb-m5 {
  margin-bottom: -5px;
}
.pt8 {
  padding-top: 8px;
}
.pt12 {
  padding-top: 12px;
}
.pt20 {
  padding-top: 20px;
}
.pb8 {
  padding-bottom: 8px;
}
.pb12 {
  padding-bottom: 12px;
}
.pb20 {
  padding-bottom: 20px;
}
.pr10 {
  padding-right: 10px;
}
.pr20 {
  padding-right: 20px;
}
.pl10 {
  padding-left: 10px;
}
.pl20 {
  padding-left: 20px;
}
.width200 {
  width: 200px;
}
.width20 {
  width: 20px;
}
.fa-upload {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin-right: 5px;
}
.fa-ban {
  display: inline-block;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin-right: 5px;
}
.upload-cancel {
  background-color: #e7505a;
  color: white;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  border: none;
  border-radius: 4px;
}
.fade-bg {
  background-color: rgb(251, 252, 253);
}
.modal-upload-disable {
  background-color: gray;
  color: white;
  text-align: center;
  padding: 6px 0;
  border: none;
  border-radius: 4px;
  // margin-top: 8px;
  padding-left: 20px;
  padding-right: 20px;
}
.add-plan-container {
  // background-color: rgb(243, 246, 249);
  background-color: white;
  padding: 0px;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 6px;
  min-height: max-content;
  // font-family: 'Roboto', sans-serif;
  font-size: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.padding-container {
  padding-left: 15px;
}
.padding-right {
  padding-right: 15px;
}
.add-plan-header {
  // font-size: '1.275rem';
  color: "#343a40";
  font-weight: "bolder!important";
  margin-top: "0.5rem";
  margin-right: "0.5rem";
  margin-bottom: "0.5rem";
  font-size: 1.275rem;
  font-size: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.account-link-container {
  // font-family: 'Roboto', sans-serif;
  font-size: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.account-link {
  margin-top: 20px;
}
.standard-account-btn {
  background-color: rgb(89, 187, 162);
  color: white;
  position: relative;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  // margin-left: 15px;
  border: none;
  border-radius: 4px;
}
.toggle-account-link {
  margin-top: 3px;
  margin-left: 40px;
}
.link-plan-title {
  margin-left: 15px;
  padding-top: 7px;
}
.bold {
  font-weight: bold;
  font-size: 14px;
}
.color-primary-green {
  color: rgb(89, 187, 162);
}
.account-plan-heading {
  background-color: white;
  padding: 5px;
  border: none;
  text-align: left;
  width: 100%;
  padding-left: 10px;
  color: black;
}
.plan-heading-arrow-180 {
  transform: rotate(180deg);
}
.plan-heading-arrow-0 {
  transform: rotate(0deg);
}
.plan-heading-italic {
  font-style: italic;
  font-weight: bold;
}
.border-line {
  width: 100%;
  height: 1px;
  background-color: #eee;
}
.border-line-black {
  width: 100%;
  height: 1px;
  background-color: black;
}
.custom-row {
  display: flex;
  align-items: center;
}
.account-plan-values-box {
  padding-top: 8px;
  background-color: rgb(247, 252, 250);
  margin-left: 10px;
  margin-right: 5px;
}
.account-plan-values-container {
  display: flex;
  // background-color: #E0E0E0;
  // background-color: rgb(243,246,249);
  align-items: center;
  padding-left: 12px;
  color: black;
}
.account-plan-values {
  background-color: #e0e0e0;
  border-width: 1px;
  margin-bottom: 8px;
  padding: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 15px;
  border-radius: 4px;
  color: black;
}
.account-link-arrow {
  padding-top: 3px;
  background-color: rgb(243, 246, 249);
  width: 30px;
  height: 30px;
  border-radius: 4px;
  justify-content: center;
  padding-left: 3px;
  margin-right: 20px;
  align-items: center;
}
.account-link-val1 {
  word-wrap: break-word;
  margin-left: 10px;
  margin-right: 20px;
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
  width: 220px;
}
.account-plan-dropdown-container {
  flex: 1;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.account-plan-dropdown {
  width: 50%;
  word-wrap: break-word;
  text-align: left;
  padding-bottom: 5px;
}
.spacing {
  margin-left: auto;
}
.plain-btn {
  border: none;
  background-color: transparent;
}
.bg-test {
  background-color: red;
}
.building-top-section {
  background-color: rgb(243, 246, 249);
  margin: 20px;
  padding: 10px;
  border-radius: 6px;
  padding-top: 20px;
  padding-bottom: 20px;
  justify-content: space-between;
}
.building-dropdown {
  width: 85%;
}
.datahub-btn {
  border: none;
  height: 35px;
  background-color: #46763c;
  color: white;
  border-radius: 4px;
  align-self: center;
  .btn-spinner {
    margin: 6px 10px !important;
    width: 1.5rem;
    height: 1.5rem;
  }
  .btn-spinner-add {
    width: 1rem !important;
    height: 1rem !important;
    margin-left: 5px;
  }
  .register-spinner {
    width: 1rem;
    height: 1rem;
    margin-left: 7px;
  }
  &:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
  }
}
.custom-header-list {
  flex-direction: row;
  span {
    margin-right: 10px;
  }
  .spinner-download-customer {
    margin: 0px !important;
    width: 2rem;
    height: 2rem;
  }
}
.btn-spinner-download {
  margin: 0 10px !important;
  width: 0.7rem;
  height: 0.7rem;
  color: #27272a;
}
.add-energy {
  justify-content: space-between;
}
.energy-dropdown {
  // width: 15%;
  flex: auto;
  height: 30px;
}
.energy-input {
  height: 30px;
}

// .container {
//   .MuiAlert-root {
//     font-size: 1em;
//     font-family: Roboto, Poppins, Helvetica, "sans-serif";
//     font-weight: normal;
//   }
// }

.apexcharts-legend-marker {
  // top: 0 !important;
}
.empty-customer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 30px;
}
.st-total {
  display: none !important;
}
.st-btn {
  min-width: 125px !important;
}
.st-label {
  display: inherit !important;
}

// .MuiTablePagination-displayedRows {
//   margin: 0 !important;
// }

// .MuiTablePagination-selectLabel {
//   margin: 0 !important;
// }

.co2-reports .react-datepicker__triangle::after {
  left: -25px !important;
}

.co2-reports .react-datepicker__triangle::before {
  left: -25px !important;
}
.meter-dropdown {
  display: flex;
  align-items: center;
  height: 30px;
  background-color: "#ffffff !important";
  border-radius: 6px;
  min-width: 200;
  font-size: 14;
  font-weight: "normal";
  flex: 1;
  color: "black";
}

.react-datepicker__navigation--next {
  background: black;
  width: 15px;
  height: 15px;
  border: none;
}
