.mapboxgl-popup-content {
  background-color: transparent;
  padding: 0;
}

.mapboxgl-custom-popup-content {
  border-radius: 3px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 10%);
  padding: 10px 10px 10px 15px;
  margin-bottom: 5px;
  pointer-events: auto;
  position: relative;
  color: white;
  background-color: black;
}

.custom-tooltip {
  content: " ";
  position: absolute;
  top: 100%; /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.epc-details {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}

.mapboxgl-popup-tip {
  display: none;
}
