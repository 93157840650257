.react-datepicker {
  width: 100%;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
  border: none;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day-names,
.react-datepicker__week,
.react-datepicker__month-wrapper {
  display: flex;
  justify-content: space-between;
}

.react-datepicker__month-wrapper {
  margin-top: 5px;
}

/* .react-datepicker__year--container, .react-datepicker__year {
  height: 100%;
} */

/* .react-datepicker__year .react-datepicker__year-text {
  padding: 5px;
  margin: 15px 5px;
  color: #27272a;
} */

.react-datepicker__year-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  height: 100%;
  max-width: 100%;
}

.react-datepicker__header {
  background-color: #fff;
  color: #27272a;
  border-bottom: none;
  font-weight: 600;
  font-size: 16px;
}

.react-datepicker__day-names {
  font-size: 12px;
  font-weight: 500;
  color: #27272a;
  padding-top: 20px;
}

.react-datepicker__navigation-icon::before {
  border-color: #27272a;
  top: 10px;
}

.react-datepicker__year .react-datepicker__year-text {
  display: flex;
  color: #27272a;
  width: 65px;
  height: 65px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.react-datepicker__month .react-datepicker__month-text {
  display: flex;
  color: #27272a;
  width: 68px;
  height: 68px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  display: flex;
  color: #27272a;
  width: 38px;
  height: 38px;
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
  pointer-events: none;
}

.react-datepicker__day--selected {
  background-color: #015731;
  color: #ffffff !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #015731;
  color: #ffffff !important;
}
.react-datepicker__month--selected {
  background-color: #015731;
  color: #ffffff !important;
}

.react-datepicker__month--keyboard-selected {
  background-color: #015731;
  color: #ffffff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__year-text:hover {
  background-color: #ccddd6;
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text.react-datepicker__month--selected:hover,
.react-datepicker__year-text--selected:hover {
  background-color: #015731;
  color: #ffffff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #015731;
  color: #ffffff !important;
}
.react-datepicker__day--disabled {
  color: #ccc;
  cursor: default;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
  color: #ccc;
  cursor: default;
}

.react-datepicker__month--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc !important;
  pointer-events: none;
}

.react-datepicker__year--disabled,
.react-datepicker__quarter--disabled {
  color: #ccc !important;
  pointer-events: none;
}

.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #ccc !important;
  pointer-events: none;
}

.custom-datepicker-calendar .react-datepicker__navigation {
  display: flex;
  flex-direction: column;
}

.custom-datepicker-calendar .react-datepicker__navigation button {
  margin-bottom: 5px;
}

.custom-datepicker-calendar .react-datepicker__day-names {
  display: flex;
  flex-direction: row-reverse;
}

.custom-datepicker-calendar .react-datepicker__day-name {
  width: 100%;
  text-align: center;
}

.custom-datepicker-calendar .react-datepicker__month {
  display: flex;
  flex-direction: row-reverse;
}

.custom-datepicker-calendar .react-datepicker__month-select {
  margin-right: 5px;
}

.custom-datepicker-calendar .react-datepicker__year-select {
  margin-right: 5px;
}
