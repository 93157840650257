$link-light-grey: #6c757d;
  $primary-orange: #fec503; //before: fed136
  $footer-background-light-grey: #212529;
  $footer-background-grey: #1a1d20;
  $social-buttons-circle-dark-grey: #1a1d20;

.public {
  .header-section {
    background-image: url(../../../../../../public/media/bg/bg-1.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 80vh;
  }

  .col-md-4:nth-child(even) {
    // background: blue;
  }
  .col-md-4:nth-child(odd) {
    // background: red;
  }

  a {
    color: $link-light-grey;
  }
  a:hover {
    color: $primary-orange;
    text-decoration: none;
  }

  ::selection {
    background: $primary-orange;
    text-shadow: none;
  }

  footer {
    padding: 2rem 0;
    background-color: #212529;
    // .container .row:not(:last-child) {
    //   padding-bottom: 2rem;
    // }
  }

  .footer-column:not(:first-child) {
    padding-top: 2rem;
    @media (min-width: 768px) {
      padding-top: 0rem;
    }
  }

  .footer-column {
    text-align: center;
    //border: red solid 1px;
    .nav-item {
      .nav-link {
        padding: 0.1rem 0;
      }
      a.nav-link {
        margin: 11px 0px !important;
      }
      span.nav-link {
        color: $link-light-grey;
      }
      span.footer-title {
        font-size: 14px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
      }
      .fas {
        margin-right: 0.5rem;
      }
    }
    ul {
      display: inline-block;
      @media (min-width: 768px) {
        text-align: left;
      }
      //border: yellow solid 1px;
    }
  }

  ul.social-buttons {
    margin-bottom: 0;
  }

  ul.social-buttons li a:active,
  ul.social-buttons li a:focus,
  ul.social-buttons li a:hover {
    background-color: $primary-orange;
  }

  ul.social-buttons li a {
    font-size: 20px;
    line-height: 40px;
    display: block;
    width: 40px;
    height: 40px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    color: #fff;
    border-radius: 100%;
    outline: 0;
    background-color: $footer-background-grey;
  }

  footer .quick-links {
    font-size: 90%;
    line-height: 40px;
    margin-bottom: 0;
    text-transform: none;
    font-family: Montserrat, "Helvetica Neue", Helvetica, Arial, sans-serif;
  }

  .copyright {
    color: white;
  }

  .fa-ellipsis-h {
    color: white;
    padding: 2rem 0;
  }
}
