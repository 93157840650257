//
// Base
//

// Body
body {
  background: $page-bg;
  // .tablenew,
  // .tablenew tr,
  .tablenew tr:nth-child(even) {
    background: #fafafa;
  }
  .tablenew tr:nth-child(odd) {
    background: #ffffff;
  }
  .tablenew tr:first-child {
    border-top: 1px solid #e4e4e7;
  }
  .tablenew td {
    // background-color: white !important;
    border: 0;
  }
  .tablenew tr td {
    font-weight: 400 !important;
    font-style: normal;
    color: #71717a !important;
    padding: 1.25rem;
    padding-left: 0.75rem;
    font-size: 14px !important;
  }
  .tablenew td:first-child {
    color: #27272a !important;

    font-weight: 600 !important;
  }
  .tablenew thead td {
    color: #27272a !important;
    font-size: 12px !important;
    font-weight: 600 !important;
  }
}

// Desktop Mode
@include media-breakpoint-up(lg) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-fixed & {
      padding-top: get($header-config, desktop, fixed, height);
    }

    // Fixed Content Head
    .header-fixed.subheader-fixed.subheader-enabled & {
      //padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
    }

    // Fixed Aside
    .aside-fixed & {
      padding-left: get($aside-config, base, width);
    }

    // Minimize Aside
    .aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
      padding-left: get($aside-config, base, minimized-width);
    }
  }

  // Container
  .container,
  .container-fluid {
    padding: 0 get($page-padding, desktop);
  }
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
  // Wrapper
  .wrapper {
    // Fixed Header
    .header-mobile-fixed & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height);
    }

    // Fixed Subheader
    .header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
      padding-top: get($header-config, tablet-and-mobile, fixed, height) +
        get($subheader-config, fixed, height-mobile);
    }
  }

  // Container
  .container,
  .container-fluid {
    max-width: none;
    padding: 0 get($page-padding, tablet-and-mobile);
  }
}
