.epc-table table {
  /* font-family: Arial, Helvetica, sans-serif; */
  border-collapse: collapse;
  width: 100%;
}

.epc-table table td {
  border: 1px solid #ddd;
  /* font: 10px sans-serif; */
  font-weight: 600;
  white-space: nowrap;
}

.epc-table table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.epc-table table tr:hover {
  background-color: #ddd;
}

.epc-table table th {
  border: 1px solid #ddd;
  padding: 4px;
  padding-top: 6px;
  padding-bottom: 6px;
  font: 10px sans-serif;
  font-weight: 600;
  text-align: left;
  background-color: #4f6172;
  color: white;
}
.arrowhead {
  clip-path: polygon(100% 0%, 100% 100%, 100% 100%, 15% 100%, 0% 50%, 15% 0%);
}
